<template>
    <b-card title="Profile">
        <b-row>

            <!-- User Info: Left col -->
            <b-col
                cols="12"
                xl="3"
                class="ml-2 d-flex justify-content-between flex-column"
            >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                    <b-img
                        ref="previewEl"
                        rounded
                        :src="require('@/assets/images/avatars/user3.png')"
                        height="100"
                    />
                    <div class="d-flex flex-column ml-1">
                        <div class="mb-1">
                            <h4 class="mb-0"> {{ userData.fullName }} </h4>
                            <span class="card-text" style="color: blue">{{ userData.email }}</span>
                        </div>
                        <div v-if="!isRoot" class="d-flex flex-wrap">
                            <b-button variant="primary" size="sm" v-b-modal.modal-change-password > Change Password </b-button>
                            <!-- <b-button variant="outline-danger" class="ml-1" size="sm" > Delete </b-button> -->
                        </div>
                    </div>
                </div>

            </b-col>

            <!-- Middle Col: Table -->
            <b-col
                cols="12"
                xl="4"
            >
                <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                        <th class="pb-50" style="width:200px">
                        <feather-icon
                            icon="UserIcon"
                            class="mr-75"
                        />
                        <span class="font-weight-bold">Username</span>
                        </th>
                        <td class="pb-50">
                        {{ userData.username }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50" style="width:200px">
                            <feather-icon
                                icon="CheckIcon"
                                class="mr-75"
                            />
                            <span class="font-weight-bold">Status</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                        {{ userData.status }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50" style="width:200px">
                            <feather-icon
                                icon="StarIcon"
                                class="mr-75"
                            />
                            <span class="font-weight-bold">Role</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                        {{ userData.role }}
                        </td>
                    </tr>
                    
                    <tr>
                        <th style="width:100px">
                        <feather-icon
                            icon="PhoneIcon"
                            class="mr-75"
                        />
                        <span class="font-weight-bold">Contact</span>
                        </th>
                        <td>
                        {{ userData.contact }}
                        </td>
                    </tr>
                </table>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
                cols="12"
                xl="4"
            >
                <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                        <th class="pb-50" style="width:200px">
                        <feather-icon
                            icon="CalendarIcon"
                            class="mr-75"
                        />
                        <span class="font-weight-bold">Start Date</span>
                        </th>
                        <td class="pb-50">
                        {{ userData.start_date }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50" style="width:200px">
                            <feather-icon
                                icon="CalendarIcon"
                                class="mr-75"
                            />
                            <span class="font-weight-bold">End Date</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                        {{ userData.end_date }}
                        </td>
                    </tr>
                    
                </table>
            </b-col>
        </b-row>

        <b-modal
            id="modal-change-password"
            cancel-variant="outline-secondary"
            ok-title="Save"
            cancel-title="Close"
            centered
            title="Change Password Form"
            @ok="onRequestChangePassword"
            >
            <b-form>
                <b-form-group>
                    <label for="new_password">Create New Password:</label>
                    <b-form-input id="new_password" v-model="newPassword" type="password" placeholder="New Password" />
                </b-form-group>
                <b-form-group>
                    <label for="confirm_password">Confirm New Password</label>
                    <b-form-input id="confirm_password" v-model="confirmPassword" type="password" placeholder="Confirm Password" />
                </b-form-group>
            </b-form>
        </b-modal>

        <!-- <b-card-body>
            <b-row>
                <b-col cols="2" class="mb-1">
                    <b-row>
                        <b-media no-body>
                            <b-media-aside>
                                <b-link>
                                <b-img
                                    ref="previewEl"
                                    rounded
                                    :src="require('@/assets/images/avatars/user3.png')"
                                    height="100"
                                />
                                </b-link>
                            </b-media-aside>
                        </b-media>
                    </b-row>
                    <b-row>
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            size="sm"
                            class="mb-75 mr-75"
                            @click="$refs.refInputEl.$el.click()"
                        >
                        Upload
                        </b-button>
                        <b-form-file
                            ref="refInputEl"
                            v-model="profileFile"
                            accept=".jpg, .png, .gif"
                            :hidden="true"
                            plain
                            @input="inputImageRenderer"
                        />
                    </b-row>
                </b-col>

                <b-col cols="10" class="mb-1">
                    <b-row>
                        <b-col class="mb-1">
                            <label>Username</label>
                            <b-form-input
                            size="sm"
                            type="text"
                            v-model="newUser.id"
                            readonly
                            ></b-form-input>
                        </b-col>

                        <b-col class="mb-1">
                            <label>Start Date</label>
                            <b-form-datepicker
                            v-model="newUser.start_date"
                            locale="en"
                            size="sm"
                            placeholder="Pilih Tanggal"
                            ></b-form-datepicker>
                        </b-col>
                        
                        <b-col class="mb-1">
                            <label>End Date</label>
                            <b-form-datepicker
                            v-model="newUser.end_date"
                            locale="en"
                            size="sm"
                            placeholder="Pilih Tanggal"
                            ></b-form-datepicker>
                        </b-col>

                    </b-row>

                    <b-row>
                        <b-col class="mb-1">
                            <label>Name</label>
                            <b-form-input
                                size="sm"
                                type="text"
                                v-model="newUser.name"
                            ></b-form-input>
                        </b-col>
                        
                        <b-col class="mb-1">
                            <label>Email</label>
                            <b-form-input
                                size="sm"
                                type="text"
                                :state="validateState($v.newUser.email)"
                                v-model="$v.newUser.email.$model"
                            ></b-form-input>
                        </b-col>
                        
                        <b-col class="mb-1">
                            <label>Role</label>
                            <b-form-select size="sm" v-model="newUser.role">
                            <option disabled value="">Pilihan</option>
                            <option v-for="(role, i) in roles" :key="i" :value="role">
                                {{ role }}
                            </option>
                            </b-form-select>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            
        </b-card-body>

=       <b-card-footer class="float-right">
            
            <b-button
                variant="gradient-danger"
                size="sm"
                class="mr-1"
                @click="$router.push({ name: 'manage-users' })"
            >
            Cancel
            </b-button>

            <b-button
                variant="gradient-primary"
                class="mr-1"
                size="sm"
                @click="onSaveChanges"
            >
            Simpan
            </b-button>
        </b-card-footer>

        <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay> -->
    </b-card>
</template>

<script>
    import {
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BRow,
        BCol,
        BButton,
        BButtonGroup,
        BTable,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BFormDatepicker,
        BFormCheckbox,
        BFormCheckboxGroup,
        BOverlay,
        BMedia, 
        BMediaAside, 
        BMediaBody, 
        BLink, 
        BImg,
        BFormFile,
    } from 'bootstrap-vue'
    import Service from "@/api/userservice";
    import RoleService from "@/api/rolepermissionservice";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { validationMixin } from "vuelidate";
    import { required, sameAs, minLength, email } from "vuelidate/lib/validators";
    import metadata from "@/api/metadata";
    import helper from "@/api/helper";
    import axios from 'axios'
    export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BButton,
        BButtonGroup,
        BTable,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BFormDatepicker,
        BFormCheckbox,
        BFormCheckboxGroup,
        BOverlay,
        BMedia, 
        BMediaAside, 
        BMediaBody, 
        BLink, 
        BImg,
        BFormFile,
    },
    data() {
        return {
            isRoot: true, 
            showLoading: false,
            roles: [],
            perPage: 5,
			pageOptions: [5, 10, 15],
			totalRows: 1,
			currentPage: 1,
			sortBy: '',
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,
			filterOn: [],
            stationsTable: {
                fields: [{key:'id', label: 'Stasiun ID', sortable: true}, {key:'name', label: 'Nama Stasiun', sortable: true}, {key:'checked', label: 'Checked'}],
                items: [
                    {
                        id: 1,
                        name: "Stasiun Meteorologi Maimum Saleh",
                        checked: false,
                    },
                    {
                        id: 2,
                        name: "Stasiun Meteorologi Malikussaleh",
                        checked: false,
                    },
                    {
                        id: 3,
                        name: "Stasiun Meteorologi Sultan Iskandar Muda",
                        checked: false,
                    },
                ],
            },
            newUser: {
                id: "",
                name: "",
                email: "",
                department: "",
                role: "",
                start_date: new Date().toString(),
                end_date: "",
                password: "",
                password_again: "",
                assigned_stations: [],
            },
            userData: {
                username:"",
                fullName: "",
                email:"",
                status: "",
                role: ""
            },
            userGroups: [],
            dataUser: '',
            newPassword: '',
            confirmPassword: ''
        };
    },
    mixins: [validationMixin],
	validations: {
		newUser: {
			password: {
				minLength: minLength(6),
			},
			password_again: {
				sameAsPassword: sameAs("password"),
			},
			email: {
				email
			}
		},
	},
    mounted() {
        // let userID = this.$route.params.userId;
        // // console.log('userID : ', userID)
        // this.getDetailUser(userID)
        // this.getListAllRole()
        this.getUserInfo()

    },
    methods: {

        onRequestChangePassword() {
            console.log("onRequestChangePassword ", this.newPassword, this.confirmPassword);
            /**
             * Open this comment when api ready.
             * */
            
            // try {
            //     Service.changePassword("data")
            //         .then((response) => {
            //             this.showLoading = false;
            //             // console.log("detail user: ", response.data);
            //             // this.items = response.data;
                        
            //             console.log('request change password', response)

            //         })
            //         .catch((error) => {
            //             this.showLoading = false;
            //             console.log("Error : ", error.response.status);
            //         });
            // } catch (error) {
            //     console.log("error: ", error);
            // }
        },

        async getUserInfo() {
            let userLogin = JSON.parse(localStorage.getItem('userLogin'))

            let username = ''
            if(userLogin.username == 'root'){
                username = 'admin1'
                this.isRoot = true

                this.userData.username = userLogin.username
                this.userData.fullName = userLogin.fullName
                this.userData.status = "active"
                this.userData.email = userLogin.email
                this.userData.role = userLogin.role
                this.userData.start_date = new Date().toISOString().split('T')[0]
                this.userData.start_date = new Date().toISOString().split('T')[0]
            }
            else {
                username = userLogin.username	
                this.isRoot = false

                const url = helper.getBaseUrl() + "/users/"+username;
                const {data} = await axios.get(url, helper.getConfig());

                console.log('data', data)

                this.userData.username = data.username
                this.userData.fullName = data.name
                this.userData.status = "active"
                this.userData.email = data.email
                this.userData.role = userLogin.role
                this.userData.start_date = new Date(data.creation_date).toISOString().split('T')[0]
                this.userData.contact = ""
                let dc = data["guillotina.behaviors.dublincore.IDublinCore"]
                if (dc.expiration_date) {
                    this.userData.end_date = new Date(dc.expiration_date).toISOString().split('T')[0]
                }
            }

        },

        async getDetailUser (userLogin) {
            if(userLogin && userLogin.username != 'root'){
                // new Promise(async (resolve) => {
                    
                //     resolve(data);
                // });
                const url = helper.getBaseUrl() + "/users/"+username;
                const {data} = await axios.get(url, helper.getConfig());

                console.log('data', data)

                this.userData.username = data.username
                this.userData.fullName = data.name
                this.userData.status = "active"
                this.userData.email = data.email
                // this.userData.role = userLogin.role
                this.userData.start_date = new Date(data.creation_date).toISOString().split('T')[0]
                this.userData.contact = "0789273498234"
                let dc = data["guillotina.behaviors.dublincore.IDublinCore"]
                if (dc.expiration_date) {
                    this.userData.end_date = new Date(dc.expiration_date).toISOString().split('T')[0]
                }
                console.log('result data', dc, this.userData)
                // this.$forceUpdate();

            }
        },

        onCheckedClick(data) {
            console.log('data', data)
            let userID = this.$route.params.userId;
            let id = data.id

            if (data.checked) {
                this.userGroups.push('bmkgentry_'+data.id)
                if (!this.userGroups.includes("obsentry")) {
                    this.userGroups.push("obsentry")
                }
            }
            else {
                this.userGroups.forEach((group, index) => {
                    if(group == 'bmkgentry_'+id) {
                        this.userGroups.splice(index,1);
                        return false;
                    }
                });
            }
            let post_data = { "user_groups": this.userGroups }

            console.log('post_data', post_data)

            this.showLoading = true;
            try {
                Service.assignUserStation(userID, post_data)
                    .then((response) => {
                        this.showLoading = false;
                        let position = 'top-center'
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Assign user "+userID+" ke "+data.name+ ' berhasil',
                                    icon: "BellIcon",
                                    variant: "success",
                                },
                            },
                            {position}
                            );
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        console.log("Error : ", error.response.status);
                    });
            } catch (error) {
                console.log("error: ", error);
            }

        },

        validateState(item) {
			const { $dirty, $error } = item;
			return $dirty ? !$error : null;
		},
        getDetailUser(userID) {
            this.showLoading = true;
            try {
                Service.getDetailUser("/users/"+userID)
                    .then((response) => {
                        this.showLoading = false;
                        // console.log("detail user: ", response.data);
                        // this.items = response.data;
                        let data = response.data
                        this.dataUser = data
                        this.newUser.id = userID//data.id
                        this.newUser.name = data.fullname ? data.fullname : data['@name']
                        this.newUser.email = data.email
                        
                        if(data.user_roles && data.user_roles.length > 0) {
                            for (var i = 0 ; i < data.user_roles.length; i++) {
                                let rolename = data.user_roles[i].split('.')
                                if(rolename[0] != 'guillotina') {
                                    this.newUser.role = rolename[1]
                                }
                            }
                        }
                        console.log('this.newUser.role', this.newUser.role)

                        this.listAllStasiun()
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        console.log("Error : ", error.response.status);
                    });
            } catch (error) {
                console.log("error: ", error);
            }
        },
        onSaveChanges() {
            this.$v.$touch();
      		if (this.$v.$invalid) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: "Data tidak valid. Periksa kembali inputan anda",
						icon: "BellIcon",
						variant: "danger",
					},
				});
				return;
			}
			else {
                let post_data = {}
                if(this.newUser.password != '' && this.newUser.password_again != '') {
                    post_data = {
                        "fullname": this.newUser['name'],
                        "email": this.newUser.email,
                        "password":this.newUser.password
                    }
                    if(this.newUser.role != ""){
                        let post_roles = ["guillotina.Member"]
                        post_roles.push('bmkgsatu.'+this.newUser.role)
                        post_data = {
                            "fullname": this.newUser['name'],
                            "email": this.newUser.email,
                            "password":this.newUser.password,
                            "user_roles": post_roles
                        }
                    }
                }
                else {
                    post_data = {
                        "fullname": this.newUser['name'],
                        "email": this.newUser.email
                    }

                    if(this.newUser.role != ""){
                        let post_roles = ["guillotina.Member"]
                        post_roles.push('bmkgsatu.'+this.newUser.role)
                        post_data = {
                            "fullname": this.newUser['name'],
                            "email": this.newUser.email,
                            "user_roles": post_roles
                        }
                    }
                }

                if (this.newUser.role != '') {
                    post_data["user_roles"] = [
                        "guillotina.Member",
                        "bmkgsatu."+this.newUser.role
                    ]
                }

                console.log('postdata', post_data)
                
                this.showLoading = true;
                try {
                    Service.saveChanges("/users/"+this.newUser.id, post_data)
                    .then((response) => {
                        this.showLoading = false;
                        console.log("savechanges user: ", response);
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            console.log("success: ", response.statusText);
                            let position = 'top-center'
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Data sudah tersimpan",
                                    icon: "BellIcon",
                                    variant: "success",
                                },
                            },
                            {position}
                            );
                            this.$router.push({ name: "manage-users"});
                        }
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        if (error.response.status == 401) {
                            this.$swal({
                                title: "Unauthorized!",
                                text: "Sesi anda telah habis. Logout dan silahkan login kembali",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }else {
                            this.$swal({
                                title: "Something wrong!",
                                text: ""+error.response.status,
                                icon: "warning",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }
                    });
                } catch (error) {
                    console.log("error: ", error);
                }
            }
        },

        getListAllRole() {
            this.showLoading = true;
			try {
				RoleService.getListAllRole()
                    .then((response) => {
                        this.showLoading = false;
                        console.log("list-roles: ", response.data.items);

                        for (var i = 0; i< response.data.items.length; i++) {
                            let role_name = response.data.items[i]['@name'].split('.')
                            this.roles.push(role_name[1])
                        }
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        console.log("Eee : ", error.response.status);
                    });
			} catch (error) {
				console.log("error: ", error);
				this.showLoading = false;
			}
        },

        async listAllStasiun() {
            let rows = [];
            let allstations = JSON.parse(localStorage.getItem('metadatastations'))
            console.log('this.dataUser.user_groups: ', this.dataUser.user_groups)
            this.userGroups = this.dataUser.user_groups
            try {

                if(allstations === null) {
                    console.log('allstations == null, ', allstations)
                    const { data } = await metadata.getAllStasiun();
                    localStorage.setItem('metadatastations', JSON.stringify(data))
                    allstations = data
                }
                
                for (var i = 0; i < allstations.length; i++) {
                    let ischeck = false
                    if (this.userGroups) {
                        for (var n = 0 ; n < this.userGroups.length; n++) {
                            let sid = this.userGroups[n].split('_')
                            if (sid[1] == allstations[i].station_id ){
                                ischeck = true
                                break
                            }
                        }
                    }
                    const row = {
                        id: allstations[i].station_id,
                        name: allstations[i].station_name,
                        checked: ischeck,
                    };
                    rows.push(row);
                }
                console.log(rows)

                this.totalRows = allstations.length

                this.stationsTable.items = rows

            } catch (e) {
                console.log(e);
            }
        },
    }
}
</script>